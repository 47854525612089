import classes from "./App.module.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import {ThemeProvider} from "@mui/material";
import theme from "./shared/theme";
import ScrollTopButton from "./components/ScrollTopButton";


function App(props) {
  return (
      <ThemeProvider theme={theme}>
              <Header/>
              <main className={classes.landingPage}>{props.children}</main>
              <Footer/>
              <ScrollTopButton/>
      </ThemeProvider>
  );
}

export default App;
