export const projects=[
    {
        id:"1",
        name:"Winery Design",
        type:"Interior Design",
        company:"pratt",
        thumbnail:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/THUMBNAIL_WINERY.jpg",
        briefIntro:"Sunlight, moonlight, firelight and natural phosphorescence exhibit the dynamic rhythm of natural light.",
        keywords:"Interactive, Lighting",
        year:"2019",
        video:"https://dzh2mqdszgu4f.cloudfront.net/videos/WINERY_DESIGN/WOW_2.mp4",
        videoIntro:"This project aims to create a space that respects and utilizes the constantly shifting rhythm of natural light, reduces reliance on artificial illumination, and adds a deeper spatial meaning, ultimately enhancing people’s awareness of the world around them.",
        videoPoster:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/VIDEO_POSTER.jpg",
        subtitle:"Natural Light floating in the Wine",
        team:"Independent Project",
        detail:{
            media: {
                bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/BG.jpg",
                main:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/MAIN.jpg",
                insert1:"https://dzh2mqdszgu4f.cloudfront.net/videos/WINERY_DESIGN/INSERT_1.mp4",
                insert2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/INSERT_2.jpg",
                wow1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/WOW_1.jpg",
                wow2:"https://dzh2mqdszgu4f.cloudfront.net/videos/WINERY_DESIGN/WOW_2.mp4",
                gallery:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/GALLERY/GALLERY_",
                    length: 52,
                    cover:[43,39,38],
                },
                videoPosters:{
                    insert1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/INSERT_1_POSTER.jpg",
                    wow2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WINERY_DESIGN/VIDEO_POSTER.jpg"
                }
            },
            text:{
                about:"This project aims to create a space that respects and utilizes the constantly shifting rhythm of natural light, reduces reliance on artificial illumination, and adds a deeper spatial meaning, ultimately enhancing people’s awareness of the world around them.\n" +
                    "Sunlight, moonlight, firelight and natural phosphorescence exhibit the dynamic rhythm of natural light. A winery located in Red Hook, surrounded by sea on three sides, designed with carefully located “gaps” using the open roof, arched windows, and strategically placed tiny holes, couples the interior space with the real, rotating and changeable world.\n",
                concept:"Both our physiology and emotions are shaped by the impact of natural light. Each stage of the winemaking process engages in a dialogue with natural light. I strive to create a space to capture the essence of ‘natural light floating in the wine’. Within the diverse and unpredictable interplay of natural light, wine tasting evolves into a nuanced and delightful experience, rendering it a more appealing activity.\n" +
                    "The most prominent characteristic of natural light lies in its continual variation. The ever-changing rhythm of light corresponds to the different times of day and seasons of the year. In the entrance lobby, a ‘sundial’ is crafted using the existing column, harnessing natural light and shadow. As the sun progresses across the sky, the shadow aligns with various hour-lines marked on the floor, indicating the time of day. Light interweaves seamlessly with its surroundings, evoking emotional responses.",
                gallery:"More images highlight the emergence of ideas and depict the design evolution of the project. The collection also incorporates additional conceptual analysis images, plans, and renderings.",
                software:"Software: Revit, Lumion, InDesign, Photoshop, and iMovie",
            }
        },
    },
    {
        id:"2",
        name:"1 Hotel, SF",
        type:"Interior Design",
        company:"dda",
        thumbnail:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/1_HOTEL_SF/THUMBNAIL_1HOTEL.jpg",
        briefIntro:"Establish a profound connection to nature through its interior architecture, design, and landscape.",
        keywords:"Sustainable, Calming",
        year:"2022",
        subtitle:"1 Hotel Guestrooms, Suites and Corridors Interior Design",
        team:"Dawson Design Associates (DDA)",
        detail:{
            media: {
                bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/1_HOTEL_SF/BG.jpg",
                main:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/1_HOTEL_SF/MAIN.jpg",
                insert1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/1_HOTEL_SF/INSERT_1.jpg",
                insert2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/1_HOTEL_SF/INSERT_2.jpg",
                wow1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/1_HOTEL_SF/WOW_1.jpg",
                wow2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/1_HOTEL_SF/WOW_2.jpg",
                gallery: {
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/1_HOTEL_SF/GALLEY/",
                    length: 26,
                    cover:[1,2,23],
                }
            },
            text:{
                about:"Dawson Design Associates was tasked with converting a distinguished, aging waterfront hotel in San Francisco into the city’s first 1 Hotel, a Luxury Brand by Starwood Capital. The hotel aims to establish a profound connection to nature through its interior architecture, design, and landscape.\n" +
                    "DDA undertook the design of 186 guest rooms, corridors, and 14 unique premier suites. Numerous rooms offer panoramic views of the harbor and Oakland Bay Bridge, including suites with terraced decks featuring sustainable furniture and green spaces designed to capture and reuse rainfall.\n",
                concept:"1 Hotels hold the belief that incorporating nature into design, coupled with a commitment to sustainability and community, can initiate dialogues that not only raise awareness but also potentially bring about meaningful change.\n"+
                "Color tones and textures were carefully selected to capture the soft grays and blurred lines of the famous fog that often wraps this building. Guestrooms and corridors are infused with native greenery, stone, cork, weavings, pottery, and reclaimed woods. Handmade carpets created from sustainable wool and natural plant fibers were selected for their soft hand and natural hues. \n"+
                "Designing the hotel rooms presented challenges arising from diverse site variations and natural materials. The task involved creating various custom built-in wardrobes and bars with integrated desktops, alongside minimalist blackened steel legs specifically designed to accommodate concrete vanities. Every detail required extensive tracking and quality control.",
                gallery:"More images highlight the emergence of ideas and depict the design evolution of the project. The collection also incorporates additional conceptual analysis images, plans, and renderings.",
                software: "Software: AutoCAD, Adobe InDesign, Photoshop, SketchUp\n" +
                    "Photos were downloaded from the website.\n",
            }
        },
    },
    {
        id:"3",
        name:"Ground Zero",
        type:"Others",
        subType:"Conceptual Model, Research Project",
        company:"pratt",
        thumbnail:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/THUMBNAIL_GROUND_ZERO.jpg",
        briefIntro:"The inspiration for the video emanated from The 9/11 Memorial & Museum in New York, commonly known as \"Ground Zero.\"",
        keywords:"Reflecting Absence",
        year:"2017",
        video:"https://dzh2mqdszgu4f.cloudfront.net/videos/GROUND_ZERO/MAIN.mp4",
        videoHome:"https://dzh2mqdszgu4f.cloudfront.net/videos/GROUND_ZERO/ground_zero.mp4",
        videoVertical:"https://dzh2mqdszgu4f.cloudfront.net/videos/GROUND_ZERO/ground_zero_vertical.mp4",
        videoIntro:"The inspiration for the homepage video emanated from the 9/11 Memorial & Museum in New York, commonly known as \"Ground Zero.\" The white hand model serves as a platform for light and shadow. The combination of natural plants and candles collectively narrates a tale of loss and reflection, bridging the past and future.",
        videoPoster:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/MAIN_POSTER.jpg",
        subtitle:"Prayer for the Deceased - The National September 11 Memorial & Museum",
        team:"Independent Project",
        detail:{
            media: {
                bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/BG.jpg",
                main:"https://dzh2mqdszgu4f.cloudfront.net/videos/GROUND_ZERO/MAIN.mp4",
                insert1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/INSERT_1.jpg",
                insert2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/INSERT_2.jpg",
                wow1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/WOW_1.jpg",
                wow2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/WOW_2.jpg",
                gallery: {
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/GALLERY/GALLERY_",
                    length: 28,
                    cover:[6,17,28],
                },
                videoPosters:{
                    main:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/GROUND_ZERO/MAIN_POSTER.jpg",
                }
            },
            text:{
                about:"In 1973, the Twin Towers of the World Trade Center were constructed and gradually became an iconic and significant landmark in New York. Unfortunately, the Twin Towers were destroyed during the September 11 attacks in 2001. Subsequently, the term 'Ground Zero' was used to describe the site, leading to a strong public demand for its reconstruction.\n" +
                    "Michael Arad designed the memorial pool, and Peter Walker planned the landscape of the memorial plaza. Their project, titled 'Reflecting Absence,' symbolizes the immeasurable loss of life and the vanished Twin Towers. The two large pools are located within the original Twin Towers' footprints. Waterfalls cascade down their sides into a central lower pool. The names of the 2,980 victims are engraved on the parapet panels of the Memorial pools in a way that encourages meaningful connections, providing a natural medium for people to express condolences.\n" +
                    "The  Memorial Plaza, adorned with over 300 oak trees, the official national tree of the United States, a contemplative space is created in Manhattan's financial district. The oak trees infuse vitality into this memorial plaza.\n",
                concept:"This model specifically highlights the National September 11 Memorial and Museum Plaza - Ground Zero. It is crafted on a 37 cm by 32 cm board, utilizing materials such as PVC board, transparent acrylic board, wood, candles, and wild grass. The design comprises two levels, with the first level representing the Memorial Plaza and the underground portion dedicated to the Memorial Museum.\n" +
                    "These two candles represent the original footprints of Twin Towers and current memorial pools, which symbolize condolence and prayer to those who lost their lives and who gave their lives to help others. \n" +
                    "The primary materials used in this model are white and transparent, providing an ideal canvas for playing with light and shadow, symbolizing a pure and clean backdrop. In moments of darkness, the comforting warmth emitted by the candle introduces the sole color, a poignant red that signifies the past, urging remembrance of the victims. During daylight, the singular color introduced is 'green,' sourced from real grass gathered in the park. This green hue symbolizes the oak trees in the plaza, aiming to inspire individuals towards a positive and meaningful life.\n",
                gallery:"Additional photos showcase the model in various lighting environments, each conveying distinct meanings. Notably, the 'oak tree' in the model naturally transitions to a warm yellow tone after two months, infusing a sense of 'life' into the design. This transformation symbolizes the passage of time and the ever-changing nature of our world.",
                software: "Photos were downloaded from the website.",
            }
        },
    },
    {
        id:"4",
        name:"Skamania Lodge",
        subtitle: "New Villa & Cabins, Public Area Design\n(Gearshed, Wine Testing, Outfitters and Back Yard)",
        type:"Interior Design",
        company:"dda",
        team:"Dawson Design Associates (DDA)",
        thumbnail:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/SKAMANIA_LODGE/THUMBNAIL_SKAMANIA.jpg",
        briefIntro:"Situated alongside the picturesque Columbia River Gorge and surrounded by verdant towering trees...",
        keywords:"Nature, Cozy, Relaxed",
        year:"2024",
        detail:{
            media: {
                bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/SKAMANIA_LODGE/BG.jpg",
                main:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/SKAMANIA_LODGE/MAIN.jpg",
                insert1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/SKAMANIA_LODGE/INSERT_1.jpg",
                insert2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/SKAMANIA_LODGE/INSERT_2.jpg",
                wow1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/SKAMANIA_LODGE/WOW_1.jpg",
                wow2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/SKAMANIA_LODGE/WOW_2.jpg",
                gallery:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/SKAMANIA_LODGE/GALLERY/",
                    length: 36,
                    cover:[21,27,1],
                }
            },
            text:{
                about:"Situated alongside the picturesque Columbia River Gorge and surrounded by verdant towering trees, Skamania Lodge offers guests an authentic Pacific Northwest experience. In response to the changing times post-COVID, the hotel has undertaken a project to enhance the resort area. \n" +
                    "DDA has been commissioned to redesign various spaces, including the Lobby gear shed (for rent outdoor adventure devices), wine tasting room, library lounge area, golf pavilion, and pro shop (outfitters and back yard). Additionally, DDA also took the design of new construction for the Villa and Cabins.\n",
                concept:"Creating a cozy and relaxed atmosphere that fostering an immersive connection with nature, regardless of whether one is indoors or outdoors, aligns with the brand's message: \"Skamania Lodge is uniquely characterized as a place where you perceive the world from a distinctive vantage point at the intersection of nature and the great outdoors.\"\n"+
                "The pro shop area underwent a conversion into an outfitter and merchandise store, accompanied by a lively beer garden. To capture the essence of the Northwest narrative, we incorporated themes related to brown bears and Bigfoot. The overall atmosphere is enhanced by interior architecture, locally sourced timber retail display shelves, and industrial-style lighting.\n"+
                "The Villa and Cabins represent new constructions designed to seamlessly blend into the forest surroundings, maintaining visual harmony with the existing treehouse. With high ceilings and expansive windows, these structures blur the boundaries between indoor and outdoor spaces, creating an immersive experience of living among towering trees, all while enjoying luxurious accommodations.",
                gallery:"More images highlight the emergence of ideas and depict the design evolution of the project. The collection also incorporates additional conceptual analysis images, plans, and renderings.",
                software: "Software: AutoCAD, Adobe InDesign, Photoshop",
            }
        },
    },
    {
        id:"5",
        company:"dda",
        name:"Hotel Ziggy",
        subtitle: "The 7th Unofficial Z Collection Hotel series - Lobby and Suite Interior Design",
        type:"Interior Design",
        thumbnail:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/HOTEL_ZIGGY/THUMBNAIL_HOTEL_ZIGGY.jpg",
        briefIntro:"This multifunctional establishment, serving as a music venue, hotel, and bar, aims to liberate music in a creatively charged space designed for rock 'n' roll enthusiasts.",
        keywords:"Music, Original, Cool",
        team:"Dawson Design Associates (DDA)",
        year:"2022",
        detail:{
            media: {
                bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/HOTEL_ZIGGY/BG.jpg",
                main:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/HOTEL_ZIGGY/MAIN.jpg",
                insert1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/HOTEL_ZIGGY/INSERT_1.jpg",
                insert2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/HOTEL_ZIGGY/INSERT_2.jpg",
                wow1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/HOTEL_ZIGGY/WOW_1.jpg",
                wow2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/HOTEL_ZIGGY/WOW_2.jpg",
                gallery:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/HOTEL_ZIGGY/GALLEY/GALLERY_",
                    length: 43,
                    cover:[1,2,3],
                }
            },
            text:{
                about:"Hotel Ziggy represents the 7th Unofficial Z Collection Hotel series. The Z Collection stands as a family of distinctive, art-focused hotels aiming to challenge and inspire guests to take a stand and actively participate.\n" +
                    "Hotel Ziggy, functioning as a hybrid music venue, hotel, and bar, is on a mission to free the music. It serves as a tribute to counterculture, establishing a space on Sunset Boulevard where emerging young musicians can showcase their talent and potentially be discovered. \n",
                concept:"Strip away any artificial elements in public spaces and reveal the authentic structure of the building. Open it up completely, allowing it to breathe. We combined a cocktail bar, pizzeria, and lobby to craft an environment that evokes the vibe of an amateur recording studio found in someone's basement or garage. \n" +
                "The public area underwent a complete renovation, significantly differing from its previous state. To ensure the client comprehended and approved of our concept, I took charge of generating a white 3D model to showcase the new layout. Once the client gave their approval, we proceeded to the next phase, where we rendered the realistic 3D model.\n"+
                "The lobby features a welcoming 60’s-style bar adorned with an extensive collection of vinyl albums. A warm maple registration desk complements the vintage lamps, and casual leather seating paired with colorful bohemian area rugs. Together, these elements contribute to creating a relaxed, liberated, and creatively charged space tailored for rock ‘n’ roll enthusiasts.",
                gallery:"More images highlight the emergence of ideas and depict the design evolution of the project. The collection also incorporates additional conceptual analysis images, plans, and renderings.",
                software: "Software: AutoCAD, Adobe InDesign, Photoshop, SketchUp, Lumion\n" +
                    "Photos were downloaded from the website.\n",
            }
        },
    },
    {
        id:"6",
        name:"Pixel Jungle",
        subtitle: "Environment Art Design of Kindergarten Playing and Resting Space",
        type:"Architecture and Landscape",
        company:"hust",
        team:"Independent Project",
        thumbnail:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/PIXEL+JUNGLE/THUMBNAIL_PIXEL_JUNGLE.jpg",
        briefIntro:"Nature is the children's best teacher. Being playful is children’s natural instinct. And the kernel of play is the interaction with the physical world…",
        keywords:"Playing, Perception, Creativity",
        year:"2016",
        detail:{
            media: {
                bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/PIXEL+JUNGLE/BG.jpg",
                main:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/PIXEL+JUNGLE/MAIN.jpg",
                insert1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/PIXEL+JUNGLE/INSERT_1.jpg",
                insert2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/PIXEL+JUNGLE/INSERT_2.jpg",
                wow1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/PIXEL+JUNGLE/WOW_1.jpg",
                wow2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/PIXEL+JUNGLE/WOW_2.jpg",
                gallery:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/PIXEL+JUNGLE/GALLERY/GALLERY_",
                    length: 27,
                    cover:[14,15,17],
                }
            },
            text:{
                about:"Traditional kindergartens often confine children to crowded and energy-lacking classrooms, where they explore the world primarily through dull books and tedious classes. In some schools, children are not even allowed to go to the playground without permission due to safety concerns.\n" +
                    "Moreover, due to technological advancements, children increasingly rely on electronic devices such as computers, iPads, and phones. Their physical activity is confined to less than one square foot as they spend most of their time simply watching screens.\n" +
                    "Actually, Nature is the children's best teacher. Being playful is children’s natural instinct. And the kernel of play is the interaction with the physical world, the cognitive process of this world through contact with surrounding things, which is called development.\n",
                concept:"This kindergarten design's primary emphasis is on the outdoor environment, play and relaxation areas, and interior organization. This initiative introduces a \"Pixel Jungle\" concept, encouraging children to break free from traditional classrooms, fostering play in a vibrant natural setting, and facilitating active learning. \n" +
                    "Within the kindergarten, every element serves as a potential toy for children, including features like a diary wall, a shadow wall for indicating time, a meadow checkerboard, and ground hopscotch. Instead of a central playground or plastic racetrack, the design incorporates gardens, orchards, and a miniature farm, providing a diverse and colorful outdoor space that promotes children's exploration, adventure, and the development of perception, hands-on skills, and creativity.\n",
                gallery:"More images highlight the emergence of ideas and depict the design evolution of the project. The collection also incorporates additional conceptual analysis images, plans, and renderings.",
                software: "Software: AutoCAD, Adobe Photoshop, SketchUp, Lumion",
            }
        },
    },
    {
        id:"7",
        name:"Water Clock",
        subtitle: "The Hourglass of the Water Clock Records the 24-hour Memory",
        type:"Architecture",
        company:"hust",
        team:"HUST Top Innovation Team (3 People)",
        thumbnail:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WATER_CLOCK/THUMBNAIL_WATER_CLOCK.jpg",
        briefIntro:"As visitors ascend the stairs, they can immerse themselves in the different views and atmospheres of each hour throughout the day in chronological order...",
        keywords:"Time, Memory",
        year:"2014",
        detail:{
            media: {
                bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WATER_CLOCK/BG.jpg",
                main:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WATER_CLOCK/MAIN.jpg",
                insert1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WATER_CLOCK/INSERT_1.jpg",
                insert2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WATER_CLOCK/INSERT_2.jpg",
                wow1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WATER_CLOCK/WOW_1.jpg",
                wow2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WATER_CLOCK/WOW_2.jpg",
                gallery:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/WATER_CLOCK/GALLERY/",
                    length: 20,
                    cover:[14,15,13],
                }
            },
            text:{
                about:"This project is a response to the theme of the competition, \"24 Memory.\" In today's busy and stressful modern lives, people are becoming increasingly indifferent to nature. Our approach involves exploring the concept of 24 hours, capturing the various lights and shadows of a day within the architectural design. Our goal is to serve as a catalyst, inspiring individuals to venture outdoors and appreciate the beautiful nuances of nature.\n",
                concept:"The architectural structure comprises two cone-shaped components. The upper four floors simulate the lights and shadows of daytime, while the two underground floors depict the subtle changes of the night. We achieve this by meticulously controlling the intensity and angles of the lights, as well as thoughtfully enhancing indoor views. The central water clock serves as both a time indicator and creates a unique acoustic effect. As visitors ascend the stairs, they can immerse themselves in the different views and atmospheres of each hour throughout the day in chronological order.\n"+
                "Integrating the notion of time flowing with cascading water drops, we positioned an \"hourglass\" atop the structure. Regardless of their location inside the architecture, visitors can immerse themselves in the auditory experience of water gently flowing, simultaneously appreciating the visual and melodic beauty of the \"water hourglass\".",
                gallery:"More images highlight the emergence of ideas and depict the design evolution of the project. The collection also incorporates additional conceptual analysis images, plans, and renderings.",
                software: "Software: AutoCAD, Adobe Photoshop, SketchUp",
            }
        },
    },
    {
        id:"8",
        name:"Medicine Pot",
        subtitle: "Chinese Medicine Hot Pot Restaurant",
        type:"Interior Design",
        company:"hust",
        team:"HUST Top Innovation Team (4 People)",
        thumbnail:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/MEDICINE_POT/THUMBNAIL_MEDICIEN_POT.jpg",
        briefIntro:"Focuses on cultivating a compelling dining ambiance centered around \"Medicated food.\"",
        keywords:"Healthy, Culture",
        year:"2015",
        detail:{
            media: {
                bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/MEDICINE_POT/BG.jpg",
                main:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/MEDICINE_POT/MAIN.jpg",
                insert1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/MEDICINE_POT/INSERT_1.jpg",
                insert2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/MEDICINE_POT/INSERT_2.jpg",
                wow1:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/MEDICINE_POT/WOW_1.jpg",
                wow2:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/MEDICINE_POT/WOW_2.jpg",
                gallery:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/MEDICINE_POT/GALLERY/",
                    length: 13,
                    cover:[11,12,13],
                }
            },
            text:{
                about:"The project involves the design of a Chinese Medicine Hot Pot Restaurant, a collaborative effort with my friend that secured the second prize in the CIID interior design competition. This design focuses on cultivating a compelling dining ambiance centered around \"Medicated food.\" The intention is twofold: firstly, to counterbalance the potential side effects of spicy and heavy-oiled conventional hot pot dishes with the nutritional benefits of medicated food; and secondly, to preserve and advance the rich heritage of traditional medicated food culture.",
                concept:"Medicated hot pot blends traditional Chinese food with traditional medicine and medicinal ingredients, creating a tasty and health-conscious dining experience. The hot pot recipes change with the seasons, offering a variety of soups. Each soup is like a \"prescription,\" emphasizing balanced nutrition and promoting traditional cultural practices.\n"+
                "The design utilizes the visual metaphor of “roots,” symbolizing the fundamental essence of all medicine. The “root” system serves as a natural means of division and enclosure within the space, giving rise to various open and semi-open seating areas and bar counters.\n",
                gallery:"More images highlight the emergence of ideas and depict the design evolution of the project. The collection also incorporates additional conceptual analysis images, plans, and renderings.",
                software: "Software: AutoCAD, Adobe Photoshop, 3D Max",
            }
        },
    },
    // {
    //     id:"9",
    //     name:"",
    //     subtitle: "",
    //     type:"",
    //     company:"",
    //     team:"",
    //     thumbnail:"",
    //     briefIntro:"",
    //     keywords:"",
    //     year:"",
    //     detail:{
    //         media: {
    //             bg:"",
    //             main:"",
    //             insert1:"",
    //             insert2:"",
    //             wow1:"",
    //             wow2:"",
    //             gallery:{
    //                 urlPrefix:"",
    //                 length: 26,
    //                 cover:[1,2,23],
    //             }
    //         },
    //         text:{
    //             about:"",
    //             concept:"",
    //             gallery:"",
    //             software: "",
    //         }
    //     },
    // },
]

export const drawings = {
        sketch:{
            furniture: {
                urls:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/drawing/sketch/furniture/DRAWING-4.1SKETCH-FURNITURE-",
                    length: 5,
                    mobileLength: 10,
                }
            },
            building:{
                urls:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/drawing/sketch/building/DRAWING-4.1SKETCH-BUILDING-",
                    length: 7,
                    mobileLength: 11,
                },
            }

        },
        painting: {
            design: {
                urls:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/drawing/painting/design/DRAWING-4.2-PAINTING-DESIGN-",
                    length: 5,
                    mobileLength: 5,
                }
            },
            graphic: [
                {
                    name:'Your Name',
                    introduction:"A series of decorative paintings influenced by a friend's Chinese name. The artwork will reflect the visual structure of the Chinese characters, employing graphics to depict scenes that encapsulate the essence of the name or person, imbuing the graphics with deeper meaning.",
                    urls:{
                        urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/drawing/painting/graphic/DRAWING-4.2-PAINTING-GRAPHIC-NAME-",
                        length: 5,
                        mobileLength: 5,
                    }
                },
                {
                    name:'Pokémon',
                    introduction: "Create a series of Pokémon-themed artworks inspired by the popularity of the Pokémon Go game. Highlighting three favorite Pokémon, each artwork will use distinct colors and backgrounds to convey their unique characteristics and skills, maintaining consistency through the incorporation of geometric elements.",
                    urls:{
                        urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/drawing/painting/graphic/DRAWING-4.2-PAINTING-GRAPHIC-POKEMON-",
                        length: 4,
                        mobileLength: 4,
                    }
                },
            ],
        },
        digital:[
            {
                name:'Your Name',
                introduction:"A series of decorative paintings influenced by a friend's Chinese name. The artwork will reflect the visual structure of the Chinese characters, employing graphics to depict scenes that encapsulate the essence of the name or person, imbuing the graphics with deeper meaning.",
                urls:{
                    urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/drawing/digital/name/DRAWING-4.3-DIGITAL-NAME-",
                    length: 4,
                    mobileLength: 8,
                }
            },
            {
                name: 'Covid-19',
                introduction: "These series images were created during the COVID-19 pandemic using an iPad and colored with Photoshop. They depict the daily life of working and studying from home during that unique period. Each illustration follows the shape of a letter, presenting an activity. Together, they make up the compilation titled \"COVID-19 STAY HOME.\"",
                urls: {
                    urlPrefix: "https://dzh2mqdszgu4f.cloudfront.net/images/drawing/digital/covid/DRAWING-4.3DIGITAL-COVID-",
                    length: 17,
                    mobileLength: 17,
                }
            },
        ],
    }

export const images={
    icons:{
        xiaohongshu:"https://dzh2mqdszgu4f.cloudfront.net/icons/icon_xiaohongshu_black.png",
        logoWhite:"https://dzh2mqdszgu4f.cloudfront.net/icons/icon_logo_a_white.png",
        logoPrimary:"https://dzh2mqdszgu4f.cloudfront.net/icons/icon_logo_a_primary.png",
        logoSecondary:"https://dzh2mqdszgu4f.cloudfront.net/icons/icon_logo_a_secondary.png",
    },
    about:{
        bg:"https://dzh2mqdszgu4f.cloudfront.net/images/about/ABOUT_BG.jpg",
        photo:"https://dzh2mqdszgu4f.cloudfront.net/images/about/photo.png",
    },
    project:{
        all:{
            bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/all/BG_ALL.jpg"
        },
        company:{
            bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/bg_company.jpg",
            arrowRight:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_arrow_right.png",
            arrowDown:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_arrow_down.png",
            dda:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_dda.jpg",
            ddaHover:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_dda_hover.jpg",
            pratt:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_pratt.jpg",
            prattHover:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_pratt_hover.jpg",
            hust:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_hust.jpg",
            hustHover:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_hust_hover.jpg",
            others:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_other.jpg",
            othersHover:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_other_hover.jpg",
            future:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_future.jpg",
            futureHover:"https://dzh2mqdszgu4f.cloudfront.net/images/project/company/image_company_future.jpg",
        },
        type:{
            bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/type/bg_type.jpg",
            int:"https://dzh2mqdszgu4f.cloudfront.net/images/project/type/image_type_int.jpg",
            intHover:"https://dzh2mqdszgu4f.cloudfront.net/images/project/type/image_type_int_hover.jpg",
            arc:"https://dzh2mqdszgu4f.cloudfront.net/images/project/type/image_type_arc.jpg",
            arcHover:"https://dzh2mqdszgu4f.cloudfront.net/images/project/type/image_type_arc_hover.jpg",
            others:"https://dzh2mqdszgu4f.cloudfront.net/images/project/type/image_type_other.jpg",
            othersHover:"https://dzh2mqdszgu4f.cloudfront.net/images/project/type/image_type_other_hover.jpg"
        },
        video:{
            bg:"https://dzh2mqdszgu4f.cloudfront.net/images/project/video/bg_video.jpg",
        }
    },
    drawing:{
        bg:"https://dzh2mqdszgu4f.cloudfront.net/images/drawing/bg_drawing.jpg",
    },
    photography:{
        bg: {
            general:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/BACKGPHOTO-5-PHOTOGRAPHY-COVER.jpg",
            architecture:'https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/BACKGPHOTO-5-PHOTOGRAPHY-ARCHITECTURE.jpg',
            art:'https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/BACKGPHOTO-5-PHOTOGRAPHY-ART.jpg',
            nature:'https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/BACKGPHOTO-5-PHOTOGRAPHY-NATURE.jpg',
            city:'https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/BACKGPHOTO-5-PHOTOGRAPHY-CITY.jpg',
            people:'https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/BACKGPHOTO-5-PHOTOGRAPHY-PEOPLE.jpg',
            food:'https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/BACKGPHOTO-5-PHOTOGRAPHY-FOOD.jpg',
        },
        architecture:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/PHOTOGRAPHY-ARCHITECTURE.jpg",
        art:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/PHOTOGRAPHY-ART.jpg",
        city:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/PHOTOGRAPHY-CITY.jpg",
        food:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/PHOTOGRAPHY-FOOD.jpg",
        nature:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/PHOTOGRAPHY-NATURE.jpg",
        people:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/common/PHOTOGRAPHY-PEOPLE.jpg",
    },
    contact:{
        bg:"https://dzh2mqdszgu4f.cloudfront.net/images/contact/bg_contact.jpg"
    },
    error:{
        bg:"https://dzh2mqdszgu4f.cloudfront.net/images/error/error.jpg"
    }

}

export const text ={
    project:{
        all:{
            picInfo:"Photo by Ariel, Kimbell Art Museum, Fort Worth, 2018",
            quote:"A design isn't finished until someone is using it.",
        },
        company:{
            picInfo:"Photo by Ariel, Liangzhu Cultural Art Center, Hangzhou, 2023",
            quote:"The only source of knowledge is experience.",
            hust:"Interdisciplinary and imaginative undergraduate projects",
            pratt:"Projects during American graduate studies specializing in interior design",
            dda:"Hospitality Design projects as an interior designer at DDA for 4.5 years.",
            others:"Engaged in multiple internship experiences and personal projects as a freelancer",
            future:"Maintain enthusiasm, keep learning, and innovative continuously."
        },
        type:{
            picInfo:"Photo by Ariel, Ferry Dock, Seattle, 2022",
            quote:"The more you know, the more you realize you don't know.",
            int:"The current industry of work and ongoing learning.",
            arc:"Engage in conceptual projects in architecture and landscape.",
            others:"Improve skills by assisting others, such as in poster design, modeling, and rendering.",
        },
        video:{
            picInfo:"Photo by Ariel, Olympic National Park, Seattle, 2022",
            quote:"Where creativity meets the lens.",
            groundZero: "The inspiration for the video emanated from The 9/11 Memorial & Museum in New York, commonly known as \"Ground Zero.\" The white hand model serves as a platform for light and shadow. The combination of natural plants and candles collectively narrates a tale of loss and reflection, bridging the past and future.",
            wineryDesign:"",
            galleryRenovation:"",
            lightingModel:"",
            elevationRendering:"",
        },
    },
    about:{
        picInfo:"Photo by Ariel, Winery Design Diagram, 2019",
        keywords:"Maintain enthusiasm,\nkeep learning, and innovate continuously",
        normalText:"Licensed interior designer with enduring passion and curiosity for design, supported by a strong international educational background and substantial experience with landscape architecture firms and hotel interior design companies. Collaborates closely with the CEO, actively involved in the entire design process from SD to CA. \n" +
            "Possesses an international perspective, advocates for a human-centric and nature-respecting design philosophy, pays attention to detail, remains committed to continuous learning, and embraces innovation. \n",
        title:"NCIDQ, LEED Green Associates",
        education:[
            {institute:"Pratt Institute",
            major:"M.F.A (Interior Design)",
            location:"New York, U.S.",
            startTime: "06/2017",
            endTime:"05/2019"},
            {institute:"Huazhong University of Science and Technology (HUST)",
            location:"Wuhan, China",
            major:"B.F.A (Art Environment Design)",
            startTime: "09/2012",
            endTime:"06/2016"},
        ],
        workExp:[
            {company:"Dawson Design Associates, INC",
            location:"Seattle, WA",
            title:"Full-time Interior Designer",
            startTime:"10/2019",
            endTime:"10/2023",
            link:"https://www.dawsondesignassociates.com/"},
            {company:"David Ling Architect Studio",
            location:"New York, NY",
            title:"Part-time Design Assistant ",
            startTime:"05/2018",
            endTime:"03/2019",
            link:"https://www.davidlingarchitect.com/"},
            {company:"UAO Landscape and Architecture Company",
            location:"Wuhan, China",
            title:"Design Intern",
            startTime:"01/2017",
            endTime:"04/2017",
            link:"http://uao-design.com/"},
        ],
        awards:[
            {name:"International Property Awards’ Hotel Interior for California Category",
            project:"Hotel Ziggy (DDA)",
            year:"2023"},
            {name:"International Property Awards’ Hotel Suite Interior for California Category",
            project:"1 Hotel San Francisco (DDA)",
            year:"2023"},
            {name:"CIID National College Students Interior Design Competition",
            project:"Silver Award",
            year:"2015"},
            {name:"The 4th IDEA-KING International Landscape Planning and Design Competition",
            project:"Silver Award",
            year:"2014"},
        ]
    },
    drawing: {
        picInfo:"Photo by Ariel, Art Work, Dallas, 2022",
        sketch:{
            furniture: {
                description:"Furniture provides both visual and tactile sensations. I take pleasure in sketching pieces that capture my attention. The items featured in this assortment are inspired by furniture discovered in diverse settings, such as at the Chicago NeoCon show and the Las Vegas HD Expo.",
            },
            building:{
                description:"Architecture is akin to a static language; through sketching, we engage in a dialogue with the architect, exploring its intricacies, revealing concealed narratives."
            },
        },
        painting:{
            design:{
                description:"Practicing and enhancing skills by emulating the paintings of others. Simultaneously, in the process of mimicking, cultivate a unique drawing style and personal habits.",
            },
            graphic: {
                description:"Drawing diverges from a camera; it is inspired by what is seen but extends beyond mere representation. This series comprises decorative artworks influenced by life but infused with personal interpretations.",
                list:[
                    {
                        name: 'Your Name',
                        intro:"A series of decorative paintings influenced by a friend's Chinese name. The artwork will reflect the visual structure of the Chinese characters, employing graphics to depict scenes that encapsulate the essence of the name or person, imbuing the graphics with deeper meaning.",
                    },
                    {
                        name: 'Pokémon',
                        intro:"Create a series of Pokémon-themed artworks inspired by the popularity of the Pokémon Go game. Highlighting three favorite Pokémon, each artwork will use distinct colors and backgrounds to convey their unique characteristics and skills, maintaining consistency through the incorporation of geometric elements.",
                    }
                ]
            }
        },
        digital:{
            list:[
                {
                    name: 'Your Name',
                    intro:"A series of decorative paintings influenced by a friend's Chinese name. The artwork will reflect the visual structure of the Chinese characters, employing graphics to depict scenes that encapsulate the essence of the name or person, imbuing the graphics with deeper meaning.",
                },
                {
                    name: 'Covid-19',
                    intro:"These series images were created during the COVID-19 pandemic using an iPad and colored with Photoshop. They depict the daily life of working and studying from home during that unique period. Each illustration follows the shape of a letter, presenting an activity. Together, they make up the compilation titled \"COVID-19 STAY HOME.\"",
                }
            ],
        }
    },
    photography: {
        picInfo:"Photo by Ariel, Quincy Street, Boston, 2019",
        quote:{
            architecture:'A room is not a room without natural light.',
            art:'Art is not what you see, but what you make others see.',
            nature:'The poetry of the earth is never dead.',
            city:'A tapestry of dreams woven into its bustling streets.',
            people:'Life\'s rhythm is composed of the diverse beats of its people.',
            food:'A universal language that speaks to the heart.',
        }
    },
    contact:{
        picInfo:"Photo by Ariel, Kimbell Art Museum, Fort Worth, 2018",
        location:"Dallas, TX, United States",
        email:"arielwork666@gmail.com"
    }
}

export const socials={
    facebook:"https://www.facebook.com/profile.php?id=100014599446787",
    instagram:"https://www.instagram.com/ariel_liu0120/",
    linkedin:"https://www.linkedin.com/in/rui-liu-0120",
    xiaohongshu:"https://www.xiaohongshu.com/user/profile/5799f6006a6a695e23b76fd9"
}

export const photography={
    architecture: [
        {
            name:'ceiling',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/ARCHITECTURE/CEILING/CEILING_",
            length:12,
        },
        {name:'start',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/ARCHITECTURE/STAIR/STAIR_",
            length:8,
        },
        {name:'others',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/ARCHITECTURE/OTHERS/",
            length:63,
        },
    ],

    art: [
        {name:'ceramic',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/ART/CERAMIC/CERAMIC_",
            length:9,
        },
        {name:'others',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/ART/OTHERS/",
            length:9,
        },
    ],
    nature: [
        {name:'caddo',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/NATURE/CADDO/CADDO_",
            length:4,
        },
        {name:'cave',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/NATURE/CAVE/CAVE_",
            length:3,
        },
        {name:"forest",
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/NATURE/FOREST/FOREST_",
            length:7,
        },
        {name:'leaves',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/NATURE/LEAVES/LEAVES_",
            length:3,
        },
        {name:'others',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/NATURE/OTHERS/OTHERS_",
            length:35,
        },
    ],
    city: [
        {name:'firework',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/CITY/FIREWORK/firework_",
            length:2,
        },
        {name:'stair',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/CITY/STAIR/STAIR_",
            length:2,
        },
        {name:'sunset',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/CITY/SUNSET/SUNSET_",
            length:7,
        },
        {name:'others',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/CITY/OTHERS/OTHERS_",
            length:45,
        },
    ],
    people:[
        {name:'others',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/PEOPLE/OTHERS/OTHERS_",
            length:33,
        },
    ],
    food:[
        {name:'breakfast',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/FOOD/BREAKFAST/BREAKFAST_",
            length:2,
        },
        {name:'desert',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/FOOD/DESERT/DESERT_",
            length:15,
        },
        {name:'dinner',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/FOOD/DINNER/DINNER_",
            length:13,
        },
        {name:'drink',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/FOOD/DRINK/DRINK_",
            length:12,
        },
        {name:'others',
            urlPrefix:"https://dzh2mqdszgu4f.cloudfront.net/images/photography/FOOD/OTHERS/OTHERS_",
            length:4,
        },
    ]
}