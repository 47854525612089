import {useState} from "react";
import classes from "./PuzzleCard.module.scss";
import {images, text} from "../../../shared/storage";

const PuzzleCard=(props)=>{
    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const nameTranslate= (name)=>{
        if(name==='arc') return 'ARCHITECTURE, LANDSCAPE';
        if(name==='int') return 'INTERIOR';
        return props.name.toUpperCase();
    }
    return(
        <div className={classes.container}
             onMouseEnter={handleMouseEnter}
             onMouseLeave={handleMouseLeave}
             onClick={props.onClick}
             style={{...props.sx}}
        >
            <img src={images.project[props.category][props.name]}
                 alt={props.name}
                 style={(isHovered||props.mobile)?{display:"none"}:{}}/>
            <img src={images.project[props.category][`${props.name}Hover`]}
                 alt={`${props.name}Hover`}
                 style={(isHovered)||props.mobile?{}:{display:"none"}}
                 onError={(event)=>{event.currentTarget.src=images.project[props.category][props.name]}}
            />
            <div style={!props.dense?{margin:0}:{}}>
                <span style={props.category==='type'?{paddingRight: '6%', height:'3rem'}:{}}>{nameTranslate(props.name)}</span>
                {!props.mobile && <p>{text.project[props.category][props.name]}</p>}
            </div>
        </div>
    );
}
PuzzleCard.defaultProps={
    dense:false,
}
export default PuzzleCard;