import classes from "./Contact.module.scss";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import {Link} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {images, socials, text} from "../shared/storage";
const Contact = ()=>{
    return (
        <div className={classes.container}>
            <div className={classes.title}>CONTACT</div>
            <div className={classes.info}>
                <div className={classes.basic}>
                    <section><LocationOnOutlinedIcon color={'secondary'} className={classes.icon}/><span>{text.contact.location}</span></section>
                    <section><EmailOutlinedIcon color={'secondary'} className={classes.icon}/> <span>{text.contact.email}</span></section>
                </div>
                <section className={classes.social}>
                    <Link href={socials.facebook} target="_blank" rel="noopener noreferrer">
                        <FacebookIcon color={'secondary'} className={classes.icon}/>
                    </Link>
                    <Link href={socials.instagram} target="_blank" rel="noopener noreferrer">
                        <InstagramIcon color={'secondary'} className={classes.icon}/>
                    </Link>
                    <Link href={socials.linkedin} target="_blank" rel="noopener noreferrer">
                        <LinkedInIcon color={'secondary'} className={classes.icon}/>
                    </Link>
                    <Link href={socials.xiaohongshu} target="_blank" rel="noopener noreferrer">
                        <img style={{filter: 'invert(86%) sepia(16%) saturate(486%) hue-rotate(341deg) brightness(99%) contrast(89%)', width:'1.913rem', userSelect:'none'}} src={images.icons.xiaohongshu} alt={"xiaohongshu"}/>
                    </Link>
                </section>
            </div>
            <span className={classes.copywrite}>&copy; Ariel Liu | Site designed by Xu</span>
        </div>
    )
}
export default Contact;