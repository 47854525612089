import classes from "./Error.module.scss";
import {useNavigate} from "react-router-dom";
import {constants} from "../shared/appConstants";

const Error = ()=>{
    const navigate = useNavigate();
    return (
        <div className={classes.container}>
            <span>ERROR</span>
            <span>404</span>
            <div className={classes.msg}>
                <span>Page not found</span>
                <span>I tried to catch some idea, but I missed it</span>
            </div>
            <button className={classes.button} onClick={()=>navigate(constants.HOME)}>Back to Home</button>
            <span className={classes.copywrite}>&copy; Ariel Liu | Site designed by Xu</span>
        </div>

    )
}
export default Error;