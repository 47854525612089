import {useMemo} from "react";

const MasonryLayout=({colNum,items,colGap=0})=>{
    const itemsInCol = useMemo(()=>{
        const res = []
        for(let i=0;i<colNum;i++){
            res.push([]);
        }
        for(let i=0;i<items.length;i++){
            res[i%colNum].push(items[i]);
        }
        return res;
    },[])

    const containerStyle={
        display:'flex',
        flexDirection:'row',
        gap:colGap
    }
    const columnStyle={
        display:'flex',
        flexDirection:'column',
        width:`${100/colNum}%`
    }
    return(
        <div style={containerStyle}>
            {itemsInCol.map((col,i)=>(
                <div key={i} style={columnStyle}>
                    {col.map(item=>(item))}
                </div>
            ))}
        </div>
    )
}
export default MasonryLayout;