import classes from "./About.module.scss";
import {images, text} from "../shared/storage";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useState} from "react";

const About = ()=>{
    const [show, setShow] = useState(false);
    const handleShowMore = ()=>{
        setShow(!show);
    }

    return (
        <div className={classes.container}>
            <img src={images.about.bg} alt="top"
                 className={classes.topPic}
            />
            <div className={classes.onPic}>
                <div className={classes.picInfo}>{text.about.picInfo}</div>
                <div className={classes.description}>
                    {text.about.keywords.split('\n').map(p=>(
                        <div key={p.slice(0,5)}>{p}</div>
                    ))}
                </div>
            </div>
            <div className={classes.intro}>
                <div className={classes.basic}>
                    <img src={images.about.photo} alt=""/>
                    <span>ARIEL LIU</span>
                    <span>{text.about.title}</span>
                </div>
                <div className={classes.summary}>
                    {text.about.normalText.split("\n").map((paragraph,i)=>(
                        <p key={i.toString()}>{paragraph}</p>
                    ))}
                </div>
                {show?
                    <div className={classes.moreButton} onClick={handleShowMore}>
                        <ArrowDropUpIcon color={"primary"} fontSize={"large"}/>
                        SHOW LESS
                    </div>
                :
                    <div className={classes.moreButton} onClick={handleShowMore}>
                        <ArrowDropDownIcon color={"primary"} fontSize={"large"}/>
                        SHOW MORE
                    </div>
                }
                {show &&
                    <div className={classes.moreInfo}>
                        <section className={classes.subsections}>
                            <div>EDUCATION</div>
                            {text.about.education.map(edu=>(
                                <div className={classes.education} key={edu.institute}>
                                    <span>{`${edu.institute} | ${edu.location}`}</span>
                                    <span>{edu.major}</span>
                                    <span>{`${edu.startTime} - ${edu.endTime}`}</span>
                                </div>
                            ))}
                        </section>
                        <section className={classes.subsections}>
                            <div>WORK EXPERIENCE</div>
                            {text.about.workExp.map(exp=>(
                                <div className={classes.exp} key={exp.company}>
                                    <span>{`${exp.company} | ${exp.location}`}</span>
                                    <span>{`${exp.startTime} - ${exp.endTime} ${exp.title}`}</span>
                                    <a href={exp.link} target={"_blank"} rel="noopener noreferrer">{exp.link}</a>
                                </div>
                            ))}
                        </section>
                        <section className={classes.subsections}>
                            <div>AWARDS</div>
                            {text.about.awards.map(award=>(
                                <div className={classes.award} key={award.name}>
                                    <span>{award.project}</span>
                                    <span>{`${award.name}, ${award.year}`}</span>
                                </div>
                            ))}
                        </section>
                    </div>
                }

            </div>

            <div style={{height:'10vw',width:'100%'}}/>
        </div>
    )
}
export default About;