import classes from "./ProjectCard.module.scss";

const ProjectCard = (props)=>{
    return (
        <div className={classes.container} onClick={props.onClick}>
            <img className={classes.thumbnail}
                 src={props.thumbnail}
                 alt={props.name}
                 loading={'lazy'}
            />
            <div className={classes.onPic}>
                <span className={classes.keywords}>{`"${props.keywords} ..."`}</span>
                <div className={classes.bgBlock}>
                    <span className={classes.projectName}>{props.name}</span>
                    <div className={classes.briefIntro}>{props.briefIntro}</div>
                </div>
                <span className={classes.projectName}
                      style={{position:'absolute',top:'3.5rem',visibility:'visible'}}>{props.name}</span>
            </div>
            <div className={classes.basic}>
                <span>{props.type.toUpperCase()}</span>
                <span>{`${props.company.toUpperCase()}, ${props.year}`}</span>
            </div>
        </div>
    )
}
export default ProjectCard;