import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Routes, Route} from "react-router-dom";
import { Navigate } from "react-router-dom";
import {constants} from "./shared/appConstants";
import Home from "./pages/Home";
import About from "./pages/About";
import Project from "./pages/projects/Project";
import Photography from "./pages/photograpies/Photography";
import Drawing from "./pages/drawings/Drawing";
import Contact from "./pages/Contact";
import Error from "./pages/Error";
import {createRoot} from "react-dom/client";
import ProjectDetail from "./pages/projects/ProjectDetail";
import PhotographyDetail from "./pages/photograpies/PhotographyDetail";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
        <BrowserRouter>
            <App>
                <Routes>
                    <Route path={constants.HOME} Component={Home}/>
                    <Route path={constants.ABOUT} Component={About}/>
                    <Route path={`${constants.PROJECT}/:filter/:value?`} Component={Project}/>
                    <Route path={`${constants.PROJECT}/detail`} Component={ProjectDetail} />
                    <Route path={constants.PHOTOGRAPHY} Component={Photography}/>
                    <Route path={`${constants.PHOTOGRAPHY}/:category`} Component={PhotographyDetail}/>
                    <Route path={`${constants.DRAWING}/:filter`} Component={Drawing}/>
                    <Route path={constants.CONTACT} Component={Contact}/>
                    <Route path={constants.ERROR} Component={Error}/>
                    <Route path="*" element={<Navigate replace to={constants.ERROR} />} />
                </Routes>
            </App>
        </BrowserRouter>
);

reportWebVitals();
