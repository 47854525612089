import classes from "./CarouselCard.module.scss"
import {Navigation, Pagination} from "swiper/modules";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import {screenDetect} from "../../../shared/functions";

const CarouselCard = (props)=>{
    const screen = screenDetect(window.innerWidth);

    /** URL composer */
    const getUrls=(urls,isMobile)=>{
        const mobile = 'MOBILE-';
        let list = []
        if(isMobile){
            for(let i=1;i<=urls.mobileLength;i++){
                list.push(`${urls.urlPrefix}${mobile}${i}.jpg`);
            }
        }else{
            for(let i=1;i<=urls.length;i++){
                list.push(`${urls.urlPrefix}${i}.jpg`);
            }
        }
        return list;
    }

    return(
        <Swiper
            style={{
                '--swiper-navigation-color': '#d0884d',
                '--swiper-pagination-color': '#d0884d',
                '--swiper-navigation-size': screen!=='mobile'?'3rem':'1.5rem',
                '--swiper-pagination-bullet-inactive-color':'#d0884d',
                '--swiper-pagination-bottom':0,
            }}
            pagination={{
                clickable: true,
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className={classes.swiper}
        >
            {props.fullImg?
            props.images.map((url, i) => (
                <SwiperSlide key={i.toString()} className={classes.slide}>
                    <img src={url} alt={`furniture ${i}`} loading={"lazy"} className={classes.img}/>
                    <div className="swiper-lazy-preloader swiper-lazy-preloader-white"/>
                </SwiperSlide>
            ))
            :
            props.images.reduce((slides,obj)=>{
                const urls = getUrls(obj.urls, screen==='mobile');
                const imgTextComponent = (
                    screen!=='mobile'?
                    <div className={classes.imgText}>
                        <img src={urls[0]} alt=""  loading={"lazy"}/>
                        <div>
                            <span>{obj.name}</span>
                            <span>{obj.introduction}</span>
                        </div>
                    </div>
                :
                        <img  src={urls[0]}
                              loading={"lazy"}
                              className={classes.imgWithPadding} alt={obj.name}/>);
                const imgComponents = urls.slice(1).map(url=>
                    <img src={url}
                         key={url}
                         alt={obj.name}
                         loading={"lazy"}
                         className={classes.imgWithPadding}/>
                )
                imgComponents.unshift(imgTextComponent)
                return slides.concat(imgComponents);
            },[])
                .map((component,i)=>(
                <SwiperSlide key={i.toString()}>
                    {component}
                </SwiperSlide>))
            }
        </Swiper>
    )
}
export default CarouselCard;