import {useSearchParams} from 'react-router-dom';
import {Dialog, IconButton} from "@mui/material";
import {projects} from "../../shared/storage";
import {useEffect, useState} from "react";
import classes from "./ProjectDetail.module.scss"
import CornerFrame from "../../components/cards/corner_frame/CornerFrame";
import {BigPlayButton, ControlBar, LoadingSpinner, Player} from "video-react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import {screenDetect} from "../../shared/functions";
import ImageViewer from "../../components/image-viewer/ImageViewer";

const ProjectDetail = () => {
    const screen = screenDetect(window.innerWidth);

    /** To locate project */
    const [searchParams] = useSearchParams();
    const name = searchParams.get('name');
    const project = projects.find(p=>p.name===name);

    /** get gallery urls */
    const gallery = project.detail.media.gallery;
    const getGallery=()=>{
        let list = []
        for(let i=1;i<=gallery.length;i++){
            list.push(`${gallery.urlPrefix}${i}.jpg`)
        }
        return list;
    }
    const getGalleryCovers=()=>{
        return gallery.cover.map(i=>`${gallery.urlPrefix}${i}.jpg`);
    }

    /** distinguish image and video */
    const isVideo=(url)=>{
        return url.slice(-3)==='mp4';
    }
    const MediaViewer = ({url, poster, width, position="absolute"})=> {
        return(
            isVideo(url) ?
                <div style={{width, position, top: 0, bottom: 0}}>
                    <Player
                        src={url}
                        poster={poster}
                    >
                        <BigPlayButton position="center"/>
                        <LoadingSpinner/>
                        <ControlBar autoHideTime={1500}/>
                    </Player>
                </div>
                :
                <img src={url} alt="main"
                     style={{width}}/>
        )
    }

    /** Image Viewer control */
    const [open,setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const openViewer = (index)=>{
        setActiveIndex(index-1);
        setOpen(true);
    }

    /** Always on top when redirected */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className={classes.container}>
            <img className={classes.topPic}
                 src={project.detail.media.bg}
                 alt={"top"}
            />
            <div className={classes.onPic}>
                <div className={classes.title}>
                    <span>{project.name}</span>
                    <span>{project.subtitle}</span>
                </div>
                <div className={classes.basicInfo}>
                    <span>Type: {project.type}{project.subType&&" - "+project.subType}</span>
                    <span>Design Team: {project.team}</span>
                    <span>Completed: {project.year} </span>
                </div>
            </div>
            <div className={classes.contentBoard}>
                {isVideo(project.detail.media.main)?
                    <CornerFrame
                        size={'2vw'}
                        thickness={'3px'}
                        color={'#d0884d'}
                        sx={{padding: '20px', margin:'8% 0'}}>
                        <Player
                            src={project.detail.media.main}
                            poster={project.detail.media.videoPosters&& project.detail.media.videoPosters.main}
                        >
                            <BigPlayButton position="center" />
                            <LoadingSpinner />
                            <ControlBar autoHideTime={1500}/>
                        </Player>
                    </CornerFrame>
                    :
                    <img src={project.detail.media.main} alt="main"
                         style={{padding: '0 1vw', width: '100%', margin:'10% 0'}}/>
                }
                <div className={classes.threeBlocks}>
                    <div className={`${classes.textBlock} ${classes.titleLeft}`}>
                        <h2>ABOUT<ArrowForwardIosIcon/></h2>
                        <div>
                            {project.detail.text.about.split("\n").map((paragraph,i)=>(
                                <p key={i.toString()}>{paragraph}</p>
                            ))}
                        </div>
                    </div>
                    <div className={screen!=='mobile'?classes.insertPics:classes.wowPics}>
                        <MediaViewer url={project.detail.media.insert1}
                                     poster={project.detail.media.videoPosters&& project.detail.media.videoPosters.insert1}
                                     width={screen!=='mobile'?'63.5%':'100%'}
                                     position={screen!=='mobile'?'absolute':'static'}/>
                        <MediaViewer url={project.detail.media.insert2}
                                     poster={project.detail.media.videoPosters&& project.detail.media.videoPosters.insert2}
                                     width={screen!=='mobile'?'35.71%':'100%'}
                                     position={screen!=='mobile'?'absolute':'static'}
                        />
                    </div>
                    <div className={`${classes.textBlock} ${classes.titleRight}`}>
                        <h2 style={{display:screen==='mobile'? "flex" : "none"}}><ArrowBackIosNewIcon/>CONCEPT</h2>
                        <div>
                            {project.detail.text.concept.split("\n").map((paragraph,i)=>(
                                <p key={i.toString()}>{paragraph}</p>
                            ))}
                        </div>
                        <h2 style={{display:screen!=='mobile'? "flex" : "none"}}><ArrowBackIosNewIcon/>CONCEPT</h2>
                    </div>
                    <div className={classes.wowPics}>
                        <MediaViewer url={project.detail.media.wow1}
                                     poster={project.detail.media.videoPosters&& project.detail.media.videoPosters.wow1}
                                     width={'100%'} position={'static'}/>
                        <MediaViewer url={project.detail.media.wow2}
                                     poster={project.detail.media.videoPosters&& project.detail.media.videoPosters.wow2}
                                     width={'100%'} position={'static'}/>
                    </div>
                    <div className={classes.galleryPics}>
                        {getGalleryCovers().map((url,i)=>(
                            <img src={url} alt="" key={i.toString()} onClick={()=>openViewer(gallery.cover[i])}/>
                        ))}
                        <div className={classes.galleryPicNum}>+ {project.detail.media.gallery.length-3}</div>
                    </div>
                    <div className={`${classes.textBlock} ${classes.titleLeft}`}>
                        <h2>GALLERY<ArrowForwardIosIcon/></h2>
                        <div>
                            {project.detail.text.gallery.split("\n").map((paragraph,i)=>(
                                <p key={i.toString()}>{paragraph}</p>
                            ))}
                        </div>
                    </div>
                    {project.detail.text.software&&
                        <div className={classes.comment}>
                            {project.detail.text.software.split("\n").map((paragraph,i)=>(
                                <p key={i.toString()}>{paragraph}</p>
                            ))}
                        </div>

                    }
                </div>
            </div>
            <div style={{height:screen!=='mobile'?'5vw':'35vw'}}/>

            <Dialog
                open={open}
                onClose={()=>setOpen(false)}
                fullScreen
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(0,0,0,.8)',

                    },
                }}
            >
                <ImageViewer images={getGallery()} initActiveIndex={activeIndex} imagesPerSlideInControl={screen==='mobile'?5:7}/>
                <IconButton
                    color='secondary'
                    onClick={()=>setOpen(false)}
                    aria-label="close"
                    style={{position:"absolute", top:0,right:0, zIndex:4}}
                >
                    <CloseIcon/>
                </IconButton>
            </Dialog>
        </div>
    );
};

export default ProjectDetail;
