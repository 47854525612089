export const screenDetect = (viewportWidth)=>{
    if (viewportWidth < 769) {
        return 'mobile';
    } else if (viewportWidth >= 769 && viewportWidth <= 960) {
        return 'tablet';
    } else if (viewportWidth >= 960 && viewportWidth <= 1260){
        return 'tablet-lg';
    } else{
        return 'desktop'
    }
}

export function toCamelCase(inputString) {
    const words = inputString.toLowerCase().split(' ');
    const firstWord = words[0];
    const camelCaseWords = words.slice(1).map(word => word.charAt(0).toUpperCase() + word.slice(1));
    return firstWord + camelCaseWords.join('');
}

export function capitalize(str){
    return str.charAt(0).toUpperCase()+str.slice(1);
}