import {createTheme} from "@mui/material";

const theme = createTheme({
    palette:{
        primary:{
            main: '#d0884d',
        },
        secondary:{
            main: '#edcfb5'
        },
        info:{
            main:'#edcfb5'
        },
        text:{
            primary: "#000000"
        }
    },
    typography: {
        normalText: {
            fontFamily:'Calibri',
            fontStyle:'italic',
            fontSize: '25pt',
            fontWeight: 'bold'
        }
    },
})
export default theme;