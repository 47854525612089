import React, { useState, useEffect } from 'react';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToTopButton = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        // Show the button when the user has scrolled down, hide it when at the top
        const handleScroll = () => {
            setIsVisible(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // For smooth scrolling
        });
    };

    const fabStyle = {
        visibility: isVisible ? 'visible' : 'hidden',
        position: 'fixed',
        bottom:'2%',
        right:'2%',
        backgroundColor:'white',
        '&:hover': 'grey',
    }

    return (
        <Fab
            sx={fabStyle}
            onClick={scrollToTop}
        >
            <KeyboardArrowUpIcon fontSize={'large'} color={'secondary'}/>
        </Fab>
    );
};

export default ScrollToTopButton;
