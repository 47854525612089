export const constants = {
    // Routes
    HOME: '/',
    ABOUT:'/about',
    PROJECT:'/project',
    PHOTOGRAPHY:'/photography',
    DRAWING:'/drawing',
    CONTACT:'/contact',
    ERROR:'/error',

    project:{
        filterList:['all','company','type','video'],
        valueList:{
            all:null,
            company:['hust','pratt','dda','others'],
            type:['int','arc','others'],
            video:null
        }
    },
    drawing:{filterList:['sketch', 'painting', 'digital']},
    photography:{categoryList:['architecture','art','nature','city','people','food'],},

}