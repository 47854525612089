import classes from "./VideoPlayer.module.scss";
import {useRef, useState} from "react";
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import {screenDetect} from "../shared/functions";
const VideoPlayer = (props) => {
    const screen = screenDetect(window.innerWidth);
    const videoRef = useRef(null);
    const [isPlaying, setPlaying] = useState(true);
    const [isMuted, setMuted] = useState(true);

    const [isLoading, setIsLoading] = useState(true);

    const handleLoadStart = () => {
        setIsLoading(true);
    };

    const handleLoadedData = () => {
        setIsLoading(false);
    };

    const handleError = () => {
        setIsLoading(false);
    };
    const togglePlayback = () => {
        const video = videoRef.current;
        if (video) {
            if (isPlaying) {
                video.pause();
            } else {
                video.play();
            }
            setPlaying(!isPlaying);
        }
    };

    const toggleMute = () => {
        const video = videoRef.current;

        if (video) {
            video.muted = !isMuted;
            setMuted(!isMuted);
        }
    };


    return (
        <div  className={classes.container}>
            {isLoading && <div style={{width: '100%', height: '100vh', backgroundColor:'black'}}/>}
            <video
                ref={videoRef}
                className={classes.element}
                autoPlay
                playsInline
                loop
                muted
                onLoadedData={handleLoadedData}
                onLoadStart={handleLoadStart}
                onError={handleError}
                onClick={togglePlayback}
            >
                <source src={props.src} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
                {isMuted ?
                    <VolumeOffIcon onClick={toggleMute} fontSize={screen==='mobile'?"large":"medium"} className={classes.muteControl}/>
                    :
                    <VolumeUpIcon onClick={toggleMute} fontSize={screen==='mobile'?"large":"medium"} className={classes.muteControl}/>}

        </div>
    );
};
export default VideoPlayer;