import classes from "./CornerFrame.module.scss"

const CornerFrame = (props)=>{
    const containerStyle={
        '--size':props.size,
        '--thickness':props.thickness,
        '--color': props.color,
        ...props.sx
    }

    return (
        <div className={classes.container} style={containerStyle}>
            <div className={classes.content}>{props.children}</div>
        </div>
    )
}
export default CornerFrame;