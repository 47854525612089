import classes from "./Footer.module.scss";
import {Link} from "@mui/material";
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import {images, socials, text} from "../shared/storage";
import {useLocation} from "react-router-dom";
const Footer = ()=>{
    const {pathname} = useLocation();

    return (
        (pathname!=='/error'&&pathname!=='/contact') &&
        <div className={classes.footer}>
            {(pathname!=='/'&&pathname!=='/photography') && <span className={classes.divider}/>}
            <div className={classes.footerMain}>
                <div className={classes.logo}>
                    <img src={images.icons.logoSecondary} alt="logo_footer"/>
                    <div>ARIEL</div>
                </div>
                <div className={classes.info}>
                    <section className={classes.social}>
                        <Link href={socials.facebook} target="_blank" rel="noopener noreferrer"><FacebookIcon sx={{filter: 'brightness(0)'}}/></Link>
                        <Link href={socials.instagram} target="_blank" rel="noopener noreferrer"><InstagramIcon sx={{filter: 'brightness(0)'}}/></Link>
                        <Link href={socials.linkedin} target="_blank" rel="noopener noreferrer"><LinkedInIcon sx={{filter: 'brightness(0)'}}/></Link>
                        <Link href={socials.xiaohongshu} target="_blank" rel="noopener noreferrer">
                            <img style={{margin:"5px", width:'1.3125rem', userSelect:'none'}} src={images.icons.xiaohongshu} alt={"xiaohongshu"}/>
                        </Link>
                    </section>
                    <span className={classes.email}>{text.contact.email}</span>
                </div>
            </div>
            <span className={classes.copywrite}>&copy; Ariel Liu | Site designed by Xu</span>
        </div>
    )
}
export default Footer;