import classes from "./Drawing.module.scss";
import {drawings, images, text} from "../../shared/storage";
import {useNavigate, useParams} from "react-router-dom";
import CarouselCard from "../../components/cards/carousel_card/CarouselCard";
import {constants} from "../../shared/appConstants";
import {useEffect} from "react";
import {screenDetect} from "../../shared/functions";


const Drawing = ()=>{
    const screen = screenDetect(window.innerWidth);
    const {filter} = useParams();

    /** Redirect */
    const navigate = useNavigate();
    const handleRedirect =(newFilter)=>{
        if(newFilter===filter) return;
        navigate(`${constants.DRAWING}/${newFilter}`)
    }
    useEffect(()=>{
        !constants.drawing.filterList.includes(filter)&&
            navigate(constants.ERROR);
    },[])

    /** URL composer */
    const getUrls=(urls,isMobile)=>{
        const mobile = 'MOBILE-';
        let list = []
        if(isMobile){
            for(let i=1;i<=urls.mobileLength;i++){
                list.push(`${urls.urlPrefix}${mobile}${i}.jpg`);
            }
        }else{
            for(let i=1;i<=urls.length;i++){
                list.push(`${urls.urlPrefix}${i}.jpg`);
            }
        }
        return list;
    }

    return (
        <div className={classes.container}>
            <img className={classes.topPic}
                 src={images.drawing.bg}
                 alt={"top"}/>
            <div className={classes.picInfo}>{text.drawing.picInfo}</div>
            <div className={classes.control}>
                <div className={classes.drawing}/>
                <ul className={classes.filter}>
                    <li className={filter==="sketch"? classes.filterFocus:""}
                        onClick={()=>{handleRedirect("sketch")}}>SKETCH</li>
                    <li className={filter==="painting"? classes.filterFocus:""}
                        onClick={()=>{handleRedirect("painting")}}>PAINTING</li>
                    <li className={filter==="digital"? classes.filterFocus:""}
                        onClick={()=>{handleRedirect("digital")}}>DIGITAL</li>
                </ul>
            </div>
            {filter==="sketch"&&
            <div className={classes.drawingBoard}>
                <div className={classes.subsection}>
                    <div className={classes.intro}>
                        <li>Furniture</li>
                        <span>{text.drawing.sketch.furniture.description}</span>
                    </div>
                    <CarouselCard images={getUrls(drawings.sketch.furniture.urls, screen==='mobile')} fullImg={true}/>
                </div>
                <div className={classes.subsection}>
                    <div className={classes.intro}>
                        <li>Building</li>
                        <span>{text.drawing.sketch.building.description}</span>
                    </div>
                    <CarouselCard images={getUrls(drawings.sketch.building.urls, screen==='mobile')} fullImg={true}/>
                </div>
            </div>
                }
            {filter==="painting"&&
            <div className={classes.drawingBoard}>
                <div className={classes.subsection}>
                    <div className={classes.intro}>
                        <li>Design</li>
                        <span>{text.drawing.painting.design.description}</span>
                    </div>
                    <CarouselCard images={getUrls(drawings.painting.design.urls, screen==='mobile')} fullImg={true}/>
                </div>
                <div className={classes.subsection}>
                    <div className={classes.intro}>
                        <li>Graphic</li>
                        <span>{text.drawing.painting.graphic.description}</span>
                    </div>
                    <CarouselCard images={drawings.painting.graphic} fullImg={false}/>
                </div>
            </div>
            }
            {filter==="digital"&&
            <div className={classes.drawingBoard}>
                {drawings.digital.map(obj=>(
                    <div className={classes.subsection} key={obj.name}>
                        <div className={classes.intro}>
                            <li>{obj.name}</li>
                            <span>{obj.introduction}</span>
                        </div>
                        <CarouselCard images={getUrls(obj.urls, screen==='mobile')} fullImg={true}/>
                    </div>
                ))}
            </div>
            }
            <div style={{height:'5vw'}}/>
        </div>
    )
}
export default Drawing;