import classes from "./Photography.module.scss";
import {images, text} from "../../shared/storage";
import {Grid} from "@mui/material";
import {capitalize, screenDetect} from "../../shared/functions";
import {useNavigate} from "react-router-dom";
import {constants} from "../../shared/appConstants";
const Photography = ()=>{
    const screen = screenDetect(window.innerWidth);

    const navigate = useNavigate();
    const handleRedirect=(category)=>{
        navigate(constants.PHOTOGRAPHY+'/'+category);
    }

    return (
        <div className={classes.container}>
            <img className={classes.topPic}
                 src={images.photography.bg.general}
                 alt={"top"}/>
            <div className={classes.picInfo}>{text.photography.picInfo}</div>
            <Grid container spacing={0} className={classes.gridContainer}>
                {constants.photography.categoryList.map(category=>(
                    <Grid item xs={screen!=='mobile'?4:6} key={category} className={classes.gridItem} onClick={()=>handleRedirect(category)}>
                            <img src={images.photography[category]}
                                 alt={category}/>
                        <div>{capitalize(category)}</div>

                    </Grid>
                ))}
            </Grid>
            <div style={{height:'5vw'}}/>
        </div>
    )
}
export default Photography;