import classes from "./PhotographyDetail.module.scss"
import {useNavigate, useParams} from "react-router-dom";

import {useEffect, useMemo, useState} from "react";
import {constants} from "../../shared/appConstants";
import {images, photography, text} from "../../shared/storage";
import InfiniteScroll from "react-infinite-scroll-component";
import {screenDetect} from "../../shared/functions";
import {Dialog, IconButton} from "@mui/material";
import ImageViewer from "../../components/image-viewer/ImageViewer";
import CloseIcon from "@mui/icons-material/Close";

const PhotographyDetail=()=>{
    const {category} = useParams();
    const screen = screenDetect(window.innerWidth);

    /** falsy url report error */
    const navigate = useNavigate();
    useEffect(()=>{
        !constants.photography.categoryList.includes(category) &&
            navigate(constants.ERROR)
    },[])

    /** Get images */
    const loadAll = ()=>{
        return photography[category].reduce((all,series)=>{
            const numList = Array.from({length: series.length}, (value, index) => index+1);
            return all.concat(numList.map(num=>series.urlPrefix+num+'.jpg'));
        },[])
    }
    const allPics = useMemo(()=> {
        return loadAll()
    },[category]);
    const [galleryImages,setGalleryImages] = useState(screen!=='mobile'?allPics.slice(0,4):allPics);
    const [hasMore,setHasMore] = useState(galleryImages.length<allPics.length);
    useEffect(()=>{
        setGalleryImages(screen!=='mobile'?allPics.slice(0,4):allPics);
    },[category])
    const fetchMore=()=>{
        if(galleryImages.length+4>allPics.length){
            setGalleryImages(allPics);
            setHasMore(false);
        }else setGalleryImages(allPics.slice(0,galleryImages.length+4));
    }

    /** Masonry Image Gallery */
    const Gallery = () => {
        const masonryList = [];
        for (let i = 0; i < galleryImages.length; i += 4) {
            const group = galleryImages.slice(i, i + 4);

            const Masonry = () => (
                <div className={classes.masonryContainer}>
                    <div className={classes.masonryCol}>
                        {group[0] && <div className={classes.shortWrapper}>
                            <img src={group[0]} alt={`${i + 1}`}
                                 className={i >= galleryImages.length - 4 ? classes.load : ''}
                                 onClick={()=>openViewer(i)}
                            />
                        </div>}
                        {group[2] && <div className={classes.longWrapper}>
                            <img src={group[2]} alt={`${i + 3}`}
                                 className={i >= galleryImages.length - 4 ? classes.load : ''}
                                 onClick={()=>openViewer(i+2)}
                            />
                        </div>}
                    </div>
                    <div className={classes.masonryCol}>
                        {group[1] && <div className={classes.longWrapper}>
                            <img src={group[1]} alt={`${i + 2}`}
                                 className={i >= galleryImages.length - 4 ? classes.load : ''}
                                 onClick={()=>openViewer(i+1)}
                            />
                        </div>}
                        {group[3] && <div className={classes.shortWrapper}>
                            <img src={group[3]} alt={`${i + 4}`}
                                 className={i >= galleryImages.length - 4 ? classes.load : ''}
                                 onClick={()=>openViewer(i+3)}
                            />
                        </div>}
                    </div>
                </div>
            );
            masonryList.push(<Masonry key={i} />);
        }
        return <>{masonryList.map((m) => m)}</>;
    };

    /** Image Viewer control */
    const [open,setOpen] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const openViewer = (index)=>{
        setActiveIndex(index);
        setOpen(true);
    }

    /** Always on top when redirected */
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return(
        <div className={classes.container}>
            <img className={classes.topPic}
                 src={images.photography.bg[category]}
                 alt={"top"}
            />
            <div className={classes.picInfo}>{text.photography.picInfo}</div>
            <div className={classes.quote}>"{text.photography.quote[category]}"</div>
            <div className={classes.imageBoard}>
                {
                    <InfiniteScroll
                    dataLength={galleryImages.length}
                    next={fetchMore}
                    hasMore={hasMore}
                    scrollThreshold={'300px'}
                    loader={<div/>}
                    endMessage={
                        <div style={{textAlign: "center"}}>
                            <div style={{height: '2vw'}}/>
                            <b>That's all for this category.</b>
                            <p>Thanks for watching.</p>
                            <div style={{height: '5vw'}}/>
                        </div>
                    }
                >
                    <Gallery/>
                </InfiniteScroll>}
            </div>

            <Dialog
                open={open}
                onClose={()=>setOpen(false)}
                fullScreen
                PaperProps={{
                    style: {
                        backgroundColor: 'rgba(0,0,0,.8)',

                    },
                }}
            >
                <ImageViewer images={allPics} initActiveIndex={activeIndex}/>
                <IconButton
                    color='secondary'
                    onClick={()=>setOpen(false)}
                    aria-label="close"
                    style={{position:"absolute", top:0,right:0, zIndex:4}}
                >
                    <CloseIcon/>
                </IconButton>
            </Dialog>
        </div>
    )
}
export default PhotographyDetail;