import classes from "./Project.module.scss";
import {useEffect, useMemo} from "react";
import {projects as allProjects, images, text} from "../../shared/storage"
import ProjectCard from "../../components/cards/project_cards/ProjectCard";
import {useNavigate, useParams} from "react-router-dom";
import {capitalize, screenDetect} from "../../shared/functions";
import PuzzleCard from "../../components/cards/puzzle_card/PuzzleCard";
import CornerFrame from "../../components/cards/corner_frame/CornerFrame";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import 'video-react/dist/video-react.css';
import {BigPlayButton, ControlBar, LoadingSpinner, Player} from "video-react";
import {constants} from "../../shared/appConstants";
import MasonryLayout from "../../components/MasonryLayout";

const Project = ()=>{
    const {filter, value} = useParams();

    /** Redirect */
    const navigate = useNavigate();
    const handleRedirect =(newFilter, newValue)=>{
        if(newFilter===filter&&newValue===value) return;
        const url = !newValue? `${constants.PROJECT}/${newFilter}` : `${constants.PROJECT}/${newFilter}/${newValue}`;
        navigate(url)
    }
    const handleClick=(name)=>{
        // navigate(`/project/detail/?name=${name}`);
        window.open(`${constants.PROJECT}/detail/?name=${name}`,"_blank" ,"noopener noreferrer");
    };

    /** falsy url handler */
    useEffect(()=>{
        if(!constants.project.filterList.includes(filter)){
            navigate(constants.ERROR);
        }else if(!!value&&!constants.project.valueList[filter]){
            navigate(constants.ERROR);
        }else if (!!value&&!constants.project.valueList[filter].includes(value)){
            navigate(constants.ERROR);
        }

    },[])

    /** Show projects based on filter */
    const projects = useMemo(()=>{
        let result;
        if(!value){
            return allProjects;
        }
        else if(value==='arc') {
            result = allProjects.filter((project)=>project[filter]==="Architecture"||project[filter]==="Landscape"||project[filter]==="Architecture and Landscape");
        }
        else if(value==='int'){
            result = allProjects.filter((project)=>project[filter]==="Interior Design");
        }else if(filter==='company'){
            result = allProjects.filter((project)=>project[filter]===value);
        }
        else{
            result = allProjects.filter((project)=>project[filter]===capitalize(value));
        }
        return result
    },[filter, value])


    /** Responsive Design */
    const screen = screenDetect(window.innerWidth);

    const handleProjectSelection=(name)=>{
        // navigate(`${constants.PROJECT}/detail/?name=${name}`);
        window.open(`${constants.PROJECT}/detail/?name=${name}` ,"_blank" ,"noopener noreferrer");

    }

    return (
        <div className={classes.container}>
            <img className={classes.topPic}
                 src={constants.project.filterList.includes(filter)? images.project[filter].bg:""}
                 alt={"top"}
            />
            <div className={classes.picInfo}>{constants.project.filterList.includes(filter)&& text.project[filter].picInfo}</div>
            <div className={classes.control}>
                <div className={classes.project}/>
                <ul className={classes.filter}>
                    <li className={filter==="all"? classes.filterFocus:""}
                        onClick={()=>{handleRedirect("all")}}>ALL</li>
                    <li className={filter==="company"? classes.filterFocus:""}
                        onClick={()=>{handleRedirect("company")}}>BY COMPANY</li>
                    <li className={filter==="type"? classes.filterFocus:""}
                        onClick={()=>{handleRedirect("type")}}>BY TYPE</li>
                    <li className={filter==='video'? classes.filterFocus:""}
                            onClick={()=>{handleRedirect('video')}}>VIDEO</li>
                </ul>
                <div className={classes.quote}>{constants.project.filterList.includes(filter)&& `"${text.project[filter].quote}"`}</div>
            </div>

            <div className={classes.projectBoard}>
                {(filter === "all"&&!value) &&
                    <MasonryLayout
                        colGap={'5rem'}
                        colNum={screen!=='mobile'?2 : 1}
                        items={allProjects.map(project => (
                                    <ProjectCard {...project} key={project.id}
                                                 onClick={()=>handleProjectSelection(project.name)}/>
                                ))}
                    />
                }

                {(filter==="company"&&!value) &&
                <div className={classes.companySelection}>
                    <div className={classes.companyRow}>
                        <PuzzleCard category={'company'}
                                    name={'hust'} dense={true}
                                    mobile={screen==='mobile'}
                                    sx={{width:'20vw'}}
                                    onClick={()=>handleRedirect('company','hust')}
                        />
                        <img src={images.project.company.arrowRight}
                             alt="arrow right"
                             className={classes.arrow}
                             style={{transform:'translateX(-10%)'}}
                        />
                        <PuzzleCard category={'company'}
                                    name={'pratt'}
                                    dense={true}
                                    mobile={screen==='mobile'}
                                    sx={{width:'20vw'}}
                                    onClick={()=>handleRedirect('company','pratt')}
                        />
                        <img src={images.project.company.arrowRight}
                             alt="arrow right"
                             className={classes.arrow}
                             style={{transform:'translateX(10%)'}}
                         />
                        <PuzzleCard category={'company'}
                                    name={'dda'} dense={true}
                                    mobile={screen==='mobile'}
                                    sx={{width:'20vw'}}
                                    onClick={()=>handleRedirect('company','dda')}
                        />
                    </div>
                    <img src={images.project.company.arrowDown} alt="arrow down" className={classes.arrowVertical}/>
                    <div className={classes.companyRow}>
                        <PuzzleCard category={'company'}
                                    name={'future'}
                                    mobile={screen==='mobile'}
                                    sx={{width:'20vw'}}/>
                        <img src={images.project.company.arrowRight}
                             alt="arrow left"
                             className={classes.arrow}
                             style={{transform: 'scaleX(-1) translateX(10%)',  paddingTop: '6vw'}}
                        />
                        <PuzzleCard category={'company'}
                                    name={'others'}
                                    mobile={screen==='mobile'}
                                    sx={{width:'20vw'}}
                                    onClick={()=>handleRedirect('company','others')}
                        />
                    </div>
                </div>}

                {(filter==="type"&&!value)&&
                    <div className={classes.typeSelection}>
                        <PuzzleCard category={'type'}
                                    name={'int'}
                                    mobile={screen==='mobile'}
                                    sx={{width: screen!=='mobile'?'20vw' : '30vw'}}
                                    onClick={()=>handleRedirect('type','int')}
                        />
                        <PuzzleCard category={'type'}
                                    name={'arc'}
                                    mobile={screen==='mobile'}
                                    sx={{width: screen!=='mobile'?'20vw' : '30vw'}}
                                    onClick={()=>handleRedirect('type','arc')}
                        />
                        <PuzzleCard category={'type'}
                                    name={'others'}
                                    mobile={screen==='mobile'}
                                    sx={{width: screen!=='mobile'?'20vw' : '30vw'}}
                                    onClick={()=>handleRedirect('type','others')}
                        />
                    </div>}

                {(filter==='video'&&!value) &&
                    allProjects.filter(p=>!!p.video).map(project=>(
                            <div className={classes.videoProjects} key={project.name}>
                                {screen!=='mobile'?
                                <CornerFrame
                                    size={'2vw'}
                                    thickness={'3px'}
                                    color={'#d0884d'}
                                    sx={{padding: '20px', width: '100%', minHeight: '30vw'}}>
                                    <Player
                                        src={project.video}
                                        poster={project.videoPoster}
                                    >
                                        <BigPlayButton position="center"/>
                                        <LoadingSpinner/>
                                        <ControlBar autoHideTime={1500}/>
                                    </Player>
                                </CornerFrame>
                                :
                                <div style={{width:'100%', transform:'scale(1.1)'}}>
                                    <Player
                                        src={project.video}
                                        poster={project.videoPoster}
                                    >
                                        <BigPlayButton position="center"/>
                                        <LoadingSpinner/>
                                        <ControlBar autoHideTime={1500}/>
                                    </Player>
                                </div>
                                }
                                <div className={classes.intro}>
                                    <div className={classes.name}>
                                        <div>{project.name}</div>
                                        {screen!=='mobile'&&<div className={classes.keywords}>{`${project.keywords} ...`}</div>}
                                    </div>
                                    <div className={classes.subtitle}>{project.subtitle}</div>
                                    {screen==='mobile'&&<div className={classes.keywords}>{`${project.keywords} ...`}</div>}
                                    <p>{project.videoIntro}</p>
                                    <span>{`${project.company.toUpperCase()}, ${project.year}`}</span>
                                    <span className={classes.button} onClick={()=>handleClick(project.name)}>see full project <ArrowRightIcon/></span>
                                </div>

                            </div>
                    ))
                }
                {!!value && (
                    // <Layout
                    //     colCount={screen!=='mobile'?2 : 1}
                    //     minWidth={100}
                    //     gap={50}
                    //     items={projects.map(project => (
                    //         <ProjectCard {...project} key={project.id} onClick={()=>handleProjectSelection(project.name)}/>
                    //     ))}
                    // />
                    <MasonryLayout
                        colGap={'5rem'}
                        colNum={screen!=='mobile'?2 : 1}
                        items={projects.map(project => (
                        <ProjectCard {...project} key={project.id}
                        onClick={()=>handleProjectSelection(project.name)}/>
                        ))}
                    />
                )}
            </div>
            <div style={{height:'5vw'}}/>
        </div>
    )
}
export default Project;
