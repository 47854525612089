import classes from "./ImageViewer.module.scss"
import {useState} from "react";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
const ImageViewer=({
        images,
        initActiveIndex=0,
        pagination=true,
        imagesPerSlideInControl = 7,
        width= '100vw',
        height ='100vh',
        gap = '1rem',
        myRef = null
    })=>{

    const [activeSlideIndex, setActiveSlideIndex] = useState(initActiveIndex);
    const length = images.length;

    function handlePrev() {
        activeSlideIndex>0&& setActiveSlideIndex((prevIndex)=>prevIndex-1);
    }

    function handleNext() {
        activeSlideIndex<length-1&& setActiveSlideIndex((prevIndex)=>prevIndex+1);
    }

    const transformStyle = {
        '--activeSlideIndex': activeSlideIndex,
        '--imagesPerSlide':imagesPerSlideInControl,
        '--containerWidth': width,
        '--containerHeight':height,
        '--gap':gap,
    };
    return(
        <div className={classes.container} style={{'--containerHeight':height}} ref={myRef}>
            <div className={classes.main}  style={transformStyle}>
                {images.map(img=>(
                    <div className={classes.slide} key={img+'main'}>
                        <TransformWrapper>
                            <TransformComponent wrapperStyle={{width:'100%',height:'70vh'}}
                                                contentStyle={{width:'100%',height:'100%'}} >
                                <img src={img}
                                     alt=""
                                     style={{width:'100%',height:'100%',objectFit:'contain'}}/>
                            </TransformComponent>
                        </TransformWrapper>
                    </div>
                ))}
            </div>

            <div className={classes.controller}  style={transformStyle}>
                {images.map((img,i)=>(
                    <div className={`${activeSlideIndex===i&& classes.activeSlide} ${classes.slide} `}
                    key={img+'controller'}
                    onClick={()=>setActiveSlideIndex(i)}>
                        <img src={img} alt="control-slide"/>
                    </div>
                ))}
            </div>

            <button className={classes.prev}
                    type={"button"}
                    onClick={handlePrev}
                    style={activeSlideIndex===0?{opacity:'.2',cursor:'default'}:{}}
            ><ArrowBackIosNewIcon/></button>

            <button className={classes.next}
                    type={"button"}
                    onClick={handleNext}
                    style={activeSlideIndex===length-1?{opacity:'.2',cursor:'default'}:{}}>
                <ArrowForwardIosIcon/></button>
            {pagination&&
                <div className={classes.pagination}>
                    <button onClick={()=>setActiveSlideIndex(0)}><FirstPageIcon/></button>
                    <div>{activeSlideIndex+1}/{length}</div>
                    <button onClick={()=>setActiveSlideIndex(length-1)}><LastPageIcon/></button>
                </div>}
        </div>
    )
}
export default ImageViewer;