import {NavLink, useNavigate} from "react-router-dom";
import {constants} from "../shared/appConstants";
import classes from "./Header.module.scss";
import {images} from "../shared/storage";
import {screenDetect} from "../shared/functions";
import MenuIcon from '@mui/icons-material/Menu';
import {Drawer} from "@mui/material";
import {useState} from "react";

const Header = ()=>{
    const navigate = useNavigate();
    const screen = screenDetect(window.innerWidth);
    const [open,setOpen] = useState(false);

    const projectRedirect=(filter)=>{
        navigate(`${constants.PROJECT}/${filter}`);
    }
    const drawingRedirect=(filter)=>{
        navigate(`${constants.DRAWING}/${filter}`);
    }
    const photographyRedirect=(filter)=>{
        navigate(`${constants.PHOTOGRAPHY}/${filter}`);
    }
    const menuContent = (
        <ul className={classes.menu}>
            <li><NavLink className={classes.navLink} to={constants.HOME}>HOME</NavLink></li>
            <li><NavLink className={classes.navLink} to={constants.ABOUT}>ABOUT</NavLink></li>
            <li className={classes.subMenuContainer}>
                <div className={classes.navLink}>PROJECT</div>
                <ul className={classes.subMenu}>
                    <li onClick={()=>projectRedirect('all')}>ALL</li>
                    <li onClick={()=>projectRedirect('company')}>BY COMPANY</li>
                    <li onClick={()=>projectRedirect('type')}>BY TYPE</li>
                    <li onClick={()=>projectRedirect('video')}>VIDEO</li>
                </ul>
            </li>
            <li className={classes.subMenuContainer}>
                <div className={classes.navLink}>DRAWING</div>
                <ul className={classes.subMenu}>
                    <li onClick={()=>drawingRedirect('sketch')}>SKETCH</li>
                    <li onClick={()=>drawingRedirect('painting')}>PAINTING</li>
                    <li onClick={()=>drawingRedirect('digital')}>DIGITAL</li>
                </ul>
            </li>
            <li className={classes.subMenuContainer}>
                <NavLink className={classes.navLink} to={constants.PHOTOGRAPHY}>PHOTOGRAPHY</NavLink>
                <ul className={classes.subMenu}>
                    <li onClick={()=>photographyRedirect('architecture')}>ARCHITECTURE</li>
                    <li onClick={()=>photographyRedirect('art')}>ART</li>
                    <li onClick={()=>photographyRedirect('nature')}>NATURE</li>
                    <li onClick={()=>photographyRedirect('city')}>CITY</li>
                    <li onClick={()=>photographyRedirect('people')}>PEOPLE</li>
                    <li onClick={()=>photographyRedirect('food')}>FOOD</li>
                </ul>
            </li>
            {/*<li><NavLink className={classes.navLink} to={constants.PHOTOGRAPHY}>PHOTOGRAPHY</NavLink></li>*/}
            <li><NavLink className={classes.navLink} to={constants.CONTACT}>CONTACT</NavLink></li>
        </ul>
    )
    return (

            <div className={classes.header}>
                <img className={classes.logo} src={images.icons.logoPrimary} alt="logo"/>
                {screen!=='mobile'?
                    menuContent
                    :
                    <div>
                        <MenuIcon color={'info'}
                                  fontSize={"large"}
                                  onClick={()=>setOpen(!open)}
                                  style={{cursor:'pointer', zIndex: 9, pointerEvents:'all'}}
                        />
                        <Drawer open={open}
                                onClose={()=>setOpen(false)}
                                anchor={'right'}
                                sx={{opacity:.85}}
                        >
                            {menuContent}
                        </Drawer>
                    </div>
                }
            </div>
    )
}
export default Header;