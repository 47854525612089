import classes from "./Home.module.scss";
import VideoPlayer from "../components/VideoPlayer";
import {images, projects} from "../shared/storage";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import {useNavigate} from "react-router-dom";
import {screenDetect} from "../shared/functions";
import {constants} from "../shared/appConstants";
const Home = ()=>{
    const screen = screenDetect(window.innerWidth);
    const showProj = projects.find(p=> p.name==="Ground Zero");
    // const navigate = useNavigate();

    const handleClick=()=>{
        // navigate(`/project/detail/?name=${showProj.name}`);
        window.open(`${constants.PROJECT}/detail/?name=${showProj.name}`,"_blank" ,"noopener noreferrer");

    };

    return (
        <div className={classes.container}>
            <div className={classes.videoFrame}>
                <VideoPlayer
                    src={screen!=='mobile'?showProj.videoHome:showProj.videoVertical}
                    top={true}
                />
            </div>
            <img className={classes.waterPrint} src={images.icons.logoWhite} alt="water print"/>
            <div className={classes.description}>
                <div className={classes.author}>Film by Ariel, {showProj.year}</div>
                <div className={classes.name} onClick={handleClick}>
                    <div>{showProj.name}</div>
                    <div>see full project</div>
                    <ArrowRightIcon/>
                </div>
            </div>
            <div style={{height:'12vw'}}/>

        </div>
    )
}
export default Home;

